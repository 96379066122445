import { CoreComponents, CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog, DialogContent } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { JobRouteDefinitions } from "modules/company/job/JobRoutes";
import { useJobViewParams } from "../_hooks";
import { selectJobQuote, selectJobQuoteOwnerBuilderName, selectJobViewQuoteManageTasks } from "../_selectors";

const JobViewQuoteView = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, stageId, quoteId, companyId, quoteRequestId, taskId } = useJobViewParams();
  const getDocListByTaskParams = { projectId, quoteId, companyId };
  const getJobQueryParams = { projectId, stageId, taskId, companyId, quoteRequestId };
  const taskParams = { projectId, stageId, taskId };

  // queries
  const getJobQuery = CommonState.Job.getJob.useQuery(getJobQueryParams);
  const getDocListByTaskQuery = CoreState.File.getDocListByQuote.useQuery(getDocListByTaskParams);
  const getTaskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(taskParams)

  // selectors
  const jobQuote = useSelector((s) => selectJobQuote(s, getJobQueryParams, { quoteId }))
  const tasks = useSelector((s) => selectJobViewQuoteManageTasks(s, getJobQueryParams, { quoteId }))
  const ownerBuilderName = useSelector((s) => selectJobQuoteOwnerBuilderName(s, getJobQueryParams));
  const jobQuoteDocList = useSelector((s) => CoreState.File.selectDocListByQuote(s, getDocListByTaskParams));

  const handleClose = () => {
    routing.go(JobRouteDefinitions.JOB_VIEW_QUOTES)
  }

  if (!jobQuote) return null;

  return (
    <Dialog open={true}>
      <CoreComponents.DialogHeader title="View Quote" onClose={() => handleClose()} />
      <DialogContent>
        <CoreModules.Task.TaskView.TaskViewQuoteView
          userLabel="Owner Builder"
          userValue={ownerBuilderName || "-"}
          quote={jobQuote}
          quoteDocs={jobQuoteDocList}
          tasks={tasks || []}
        />
      </DialogContent>
    </Dialog>
  )
}
export default JobViewQuoteView;