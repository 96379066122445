import { CoreComponents, CoreModules, CoreState } from "@build-buddy/core";
import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useSelector } from "common/state/store";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useJobViewParams, useQueryJob } from "../../_hooks";
import { selectJobQuoteCurrent, selectJobQuoteManageTaskChanged, selectJobQuoteOwnerBuilderName, selectJobViewQuoteManageTasks } from "../../_selectors";
import JobViewQuoteConfirmValidation from "./JobViewQuoteConfirmValidation";

interface JobViewAcceptQuoteConfirmStandardProps {
  onClose(): void;
}
const JobViewAcceptQuoteConfirmStandard = (props: JobViewAcceptQuoteConfirmStandardProps) => {
  const queryJob = useQueryJob();

  // params
  const { projectId, taskId, companyId, stageId, quoteRequestId } = useJobViewParams();
  const getManageTaskParams = { projectId, stageId, taskId, companyId, quoteRequestId };
  const taskParams = { projectId, stageId, taskId };

  // mutations
  const [acceptQuoteAction, acceptQuoteQuery] = CoreState.Quote.acceptQuote.useMutation();
  const [changeDatesTaskAction, changeDatesTaskQuery] = CoreState.Task.changeDatesTask.useMutation();

  // locals
  const isSaving = acceptQuoteQuery.isLoading || changeDatesTaskQuery.isLoading;
  const isSaved = acceptQuoteQuery.isSuccess && !acceptQuoteQuery.isUninitialized;

  // selectors
  const jobQuote = useSelector((s) => selectJobQuoteCurrent(s, queryJob.jobParams));
  const ownerBuilderName = useSelector((s) => selectJobQuoteOwnerBuilderName(s, queryJob.jobParams))
  const jobQuoteManageTaskChanged = useSelector((s) => selectJobQuoteManageTaskChanged(s, queryJob.jobParams));

  // Get quote docs
  const quoteId = jobQuote?.id || '';
  const tasks = useSelector((s) => selectJobViewQuoteManageTasks(s, getManageTaskParams, { quoteId }))
  const getDocListByQuoteParams = { projectId, quoteId, companyId };
  const jobQuoteDocList = useSelector((s) => CoreState.File.selectDocListByQuote(s, getDocListByQuoteParams));
  const getTaskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(taskParams)

  const formik = useFormik({
    initialValues: {
      name: "",
      sow: false,
      tnc: false
    },
    validationSchema: JobViewQuoteConfirmValidation,
    onSubmit: () => {
      if (!quoteId) return;
      acceptQuoteAction({
        projectId,
        quoteId,
      })

      if (!jobQuoteManageTaskChanged) return;

      changeDatesTaskAction({
        projectId,
        parentTaskId: taskId,
        taskId: jobQuoteManageTaskChanged.taskPublicId,
        startDate: new Date(jobQuoteManageTaskChanged.startDate),
        endDate: new Date(jobQuoteManageTaskChanged.endDate),
        companyId
      })
    }
  })

  useEffect(() => {
    if (!isSaved) return;
    props.onClose();
  }, [isSaved])

  if (!jobQuote) return null;

  return (
    <Dialog open={true}>
      <CoreComponents.Loader show={isSaving} />
      <CoreComponents.DialogHeader
        title="Accept Offer"
        onClose={() => props.onClose()}
      />
      <DialogContent sx={{ pb: 0 }}>
        <CoreModules.Task.TaskView.TaskViewQuoteView
          quote={jobQuote}
          quoteDocs={jobQuoteDocList}
          userLabel="Owner Builder"
          userValue={ownerBuilderName || "-"}
          tasks={tasks || []}
        />
        <Box sx={{ mt: 3 }}>
          <CoreComponents.TermsAndSign
            name={{
              id: "name",
              value: formik.values.name,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error: Boolean(formik.errors.name && formik.touched.name) && formik.errors.name
            }}
            // TEMPORARY REMOVAL
            // sow={{
            //   id: "sow",
            //   value: formik.values.sow,
            //   onChange: formik.handleChange,
            //   onBlur: formik.handleBlur,
            //   error: Boolean(formik.errors.sow && formik.touched.sow) && formik.errors.sow
            // }}
            tnc={{
              id: "tnc",
              value: formik.values.tnc,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error: Boolean(formik.errors.tnc && formik.touched.tnc) && formik.errors.tnc
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ pt: 0 }}>
        <CoreComponents.Actions
          onSubmitClick={() => formik.handleSubmit()}
          onCancelClick={() => props.onClose()}
          submitText="Confirm"
          cancelText="Cancel"
        />
      </DialogActions>
    </Dialog>
  )
}
export default JobViewAcceptQuoteConfirmStandard